.header {
  background-color: transparent;

  padding: 0 10%;
  border-bottom: 0.1rem white solid;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.responsivebar {
  width: 40rem;
  background-color: #ffffff;
  height: auto;
  position: absolute;
  left: 0;
  display: none;
  justify-content: center;
  border-bottom-right-radius: 2.5rem;
  opacity: 90%;
}
#responsive {
  display: flex;
}
.responsivebar ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.responsivebar ul li {
  list-style: none;
  font-size: 2rem;
  padding: 2rem;
  padding-left: 10rem;
}
.responsivebar ul li:hover {
  background-color: #f0e5d8;
}
.responsivebar ul li a {
  color: #4d2a40;
}

.headerres {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .rightnavbar {
  display: flex;
  align-items: center;
}
.header .navbar a {
  font-size: 1.4rem;
  color: white;
  margin: 0 0.75rem;
  border-bottom: 0.1rem solid transparent;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.header .leftnavbar a {
  font-size: 1.4rem;
  color: white;
  margin: 0 0.75rem;
  border-bottom: 0.1rem solid transparent;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header .navbar .active,
.header .navbar a:hover {
  border-color: white;
  padding-bottom: 0.5rem;
}

.header .leftnavbar a {
  color: rgb(202, 200, 200);
  border: 0.2rem solid rgb(202, 200, 200);
  padding: 0.7rem;
  border-radius: 300px;
}
.header .leftnavbar a:hover {
  border-radius: 10px;
}
.header .buttons button {
  background: none;
  color: white;
  font-size: 1.4rem;
}
.fa-bars {
  display: none;
}
.fa-xmark {
  display: none;
}
.icon {
  width: 20px;
}
.logo img {
  margin: 0.5rem;
  height: 6rem;
}

@media (max-width: 845px) {
  .responsivebar .nav-mode {
    display: flex;
  }
  #kapanav {
    display: none;
  }
  .fa-bars {
    display: flex;
    font-size: 2.5rem;
    color: white;
    cursor: pointer;
  }
  .fa-xmark {
    display: flex;
    font-size: 2.5rem;
    color: white;
    cursor: pointer;
  }
  .header .leftnavbar a {
    font-size: 2rem;
  }
  .navbar {
    display: none;
  }
  .leftnavbar span {
    display: none;
  }
  .logo img {
    margin-left: 2rem;
  }
  #navbarac {
    cursor: pointer;
  }
}
@media (max-width: 450px) {
  .responsivebar {
    width: 100%;
    border-radius: 0;
  }
  .responsivebar ul {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .responsivebar ul li {
    width: 100%;
    padding-left: 10%;
  }
}

.footer {
  background-color: #f2f2f2;
  height: 27.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding-top: 3rem;
  padding-bottom: 1.65rem;
  overflow: hidden;
}
.footer .footerbottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .footerbottom p {
  margin-top: 1rem;
  font-size: 1.5rem;
}
.footer .footertop {
  display: flex;
  justify-content: space-between;
}
.footer h3 {
  color: #4d2a40;
  text-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.footer p {
  color: #4d2a40;
  font-weight: 320;
  margin-left: 0.5rem;
}

.footer .footerleft {
  margin-left: 10%;
}
.footer .footerleft p {
  width: 50rem;
  font-size: 1.3rem;
}
.footer .footermiddle p {
  width: 28.5rem;
  font-size: 1.3rem;
}
.footer .footermiddle a {
  color: black;
}
.footer .footerright {
  margin-right: 10%;
}
.footer .footerright p {
  width: 20rem;
  font-size: 1.3rem;
}
.footer .footerright a {
  color: black;
}
.footer a:hover {
  color: #4d2a40;
  font-weight: 400;
}
@media (max-width: 450px) {
  .footertop {
    flex-wrap: wrap;
  }
  .footer .footermiddle,
  .footer .footerright {
    display: none;
  }
  .footer {
    height: 31rem;
  }
  .footer .footerbottom p {
    margin-top: 2rem;
    color: #4d2a40;
    font-weight: 400;
    font-size: 1.75rem;
  }
}

.home {
  min-height: 50vh;
  margin-top: -7.75rem;
  align-items: center;
  display: flex;
  background-image: url(../Assets/images/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
  isolation: isolate;
}
.home::after {
  content: '';
  position: absolute;
  z-index: -2;
  inset: 0;
  background-color: #4d2a40;
  opacity: 0.7;
}
.home .content {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}
.home .content img {
  height: 30rem;
  text-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  margin-left: 10%;
}
.home .content h3 {
  font-size: 6rem;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: 5rem;
}
.home .content p {
  font-size: 2rem;
  font-height: 6rem;
  font-weight: 300;
  line-height: 1.8;
  color: white;
  padding: 1rem;
  margin-left: 5rem;
  margin-right: 10%;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
}
.barut {
  margin-top: 2rem;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.barut .yap .ulessbtn {
  text-align: center;
  font-size: 1.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 1.5rem;
  margin: 0rem 100rem;
  background: #4d2a40;
  border: 0.1rem #4d2a40 solid;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.barut .yap p {
  font-size: 1.5rem;
  color: #4d2a40;
  overflow: hidden;
  text-overflow: ellipsis;
}
.barut .yap h1 {
  padding-top: 1rem;
  font-size: 2.5rem;
  color: #4d2a40;
}
.boxes {
  margin-top: 2rem;
  display: flex;
  margin-bottom: 3rem;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
  gap: 2.5rem;
}
.boxes p {
  text-align: center;
  font-size: 1.25rem;
}
.boxes .box1 {
  border-radius: 2.5rem;
  width: 36rem;
  height: 34.5rem;
  padding: 3rem;
  margin-top: 1.5rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.boxes .ulessbtn {
  text-align: center;
  font-size: 1.3rem;
  padding: 0.2rem 0.8rem;
  margin-bottom: 1rem;
  border-radius: 1.5rem;
  margin-top: 1rem;
  background: #4d2a40;
  border: 0.1rem #4d2a40 solid;
  color: white;
}
.projects {
  background-color: #f0e5d8;
  height: 60rem;
}
.projects .pbas {
  padding-top: 1rem;
  align-items: center;
  text-align: center;
}
.projects .pbas .ulessbtn {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 1.5rem;
  background: #4d2a40;
  border: 0.1rem #4d2a40 solid;
  color: #f0e5d8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.projects .pbas p {
  font-size: 1.5rem;
  color: #4d2a40;
  overflow: hidden;
  text-overflow: ellipsis;
}
.projects .pbas h2 {
  padding-top: 1rem;
  font-size: 2.5rem;
  color: #4d2a40;
}
.projects .palt {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  gap: 0;
  margin-top: 2rem;
}
.projects .palt .pcards {
  display: flex;
  align-items: center;
}
.projects .palt .pcards .pcardbox {
  display: flex;
  margin-right: 10%;
  background-color: white;
  width: 50rem;
  height: 40rem;
  border-radius: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 2rem;
}
.projects .palt .pcards .pcardbox img {
  height: 100%;
  border: 0.5rem solid #4d2a40;
  border-radius: 2.5rem;
}
.projects .palt .pcards .pcardbox .pbinfo {
  overflow: hidden;
  align-items: center;
}
.projects .palt .pcards .pcardbox .pbinfo h3 {
  margin-left: 2rem;
  font-size: 2.5rem;
  color: #4d2a40;
}
.projects .palt .pcards .pcardbox .pbinfo p {
  margin-left: 2.25rem;
  font-size: 1.25rem;
  color: #4d2a40;
}
.demo-button {
  background-color: #4d2a40;
  border: none;
  color: white;
  display: inline-block;
  font-size: 1.6rem;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 90%;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  text-align: center;
}
.demo-button:hover {
  background-color: white;
  color: #4d2a40;
  cursor: pointer;
}
.projects .palt .pcontact {
  width: auto;
  margin-left: 5%;
}
.projects .palt .pcontact h3 {
  font-size: 3.5rem;
  color: #4d2a40;
}
.projects .palt .pcontact p {
  font-size: 1.75rem;
  color: #4d2a40;
  font-weight: 330;
}
.projects .palt .pcontact a:hover {
  color: #474554;
  font-weight: 400;
  cursor: pointer;
  background-color: #f0e5d8;
}
.projects .palt .cbuttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.projects .palt .cbuttons .discord-button {
  background-color: #7289da;
  border: none;
  color: white;
  display: inline-block;
  font-size: 1.6rem;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 80%;
  text-align: center;
}
.projects .palt .cbuttons .whatsapp-button {
  background-color: #49c95a;
  border: none;
  color: white;
  display: inline-block;
  font-size: 1.6rem;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 80%;
  text-align: center;
}
.homeblog {
  min-height: 28vh;
  margin-top: -7.75rem;
  align-items: center;
  display: flex;
  background-image: url(../Assets/images/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
  isolation: isolate;
}
.homeblog::after {
  content: '';
  position: absolute;
  z-index: -2;
  inset: 0;
  background-color: #4d2a40;
  opacity: 0.7;
}
.homeblog .contentblog {
  display: flex;
  flex-direction: column;
  margin-top: 9.5rem;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  width: 100%;
}
.homeblog .contentblog h3 {
  font-size: 4.1rem;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.homeblog .contentblog p {
  font-size: 1.65em;
  font-height: 6rem;
  font-weight: 300;
  line-height: 1.8;
  color: white;
  padding: 1rem;
  text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
}
.blogs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
  margin: auto;
}
.card {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 33rem;
}
.card-header img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2rem;
  min-height: 15rem;
}
.cardbutton {
  width: 100%;
}
.tag {
  background-color: #ccc;
  color: #fff;
  border-radius: 2.5rem;
  font-size: 1.3rem;
  margin: 0;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
}
.tag-teal {
  background-color: #4d2a40;
}
.card-body h4 {
  margin: 1rem 0;
}
.card-body p {
  font-size: 1.4rem;
  margin: 0 0 4rem 0;
  font-weight: 500;
  color: rgb(70, 68, 68);
}
@media (max-width: 1600px) {
  .projects {
    height: 60rem;
  }
}
@media (max-width: 1400px) {
  .projects .palt {
    flex-wrap: wrap;
  }
  .projects {
    height: 85rem;
  }
  .projects .palt .pcards .pcardbox {
    width: 53rem;
  }
  .projects .palt .pcontact {
    margin-top: 1.5rem;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1100px) {
  .boxes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .container .card {
    width: 40rem;
  }
  .projects .palt .pcards {
    flex-wrap: wrap;
  }
  .projects .palt .pcards .pcardbox {
    margin-top: 1.25rem;
  }
  .projects {
    height: 125rem;
  }
}
@media (max-width: 845px) {
  html {
    font-size: 50%;
  }
  .home .content h3 {
    font-size: 4.5rem;
  }
  .home .content p {
    font-size: 1.75rem;
  }
  .home {
    margin-top: -10rem;
  }
  .boxes .box1 {
    width: 40rem;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 40%;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .container .card {
    width: auto;
  }
  .home .content img {
    display: none;
  }
  .home .content h3 {
    font-size: 5rem;
  }
  .home .content p {
    font-size: 2.25rem;
  }
  .home {
    min-height: 40vh;
  }

  .boxes .box1 {
    width: 48rem;
  }
  .boxes .box1 p {
    font-size: 1.5rem;
  }
  .projects {
    height: 130rem;
  }
}
